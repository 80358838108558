import { graphql } from 'gatsby'
import * as React from 'react'

import LandingPage, { LandingPageProps } from '../components/pages/LandingPage'
import { candidateI18nEn } from '../language/candidateEn'
import DefaultLayout from '../layouts'

const Index = (props: LandingPageProps) => (
  <DefaultLayout language="en">
    <LandingPage
      i18n={candidateI18nEn}
      {...props}
      otherLanguageUrl="/he/candidate"
      languagePrefix="/"
      isClient={false}
    />
  </DefaultLayout>
)

export default Index

export const pageQuery = graphql`
  query {
    ...SiteData
  }
`
