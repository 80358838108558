import { i18nEn } from './en'

export const candidateI18nEn = {
  ...i18nEn,
  tagLine: `Agents for Israel's top freelance talent`,
  subtitle: `Skilled is an elite network of Israeli freelance developers. Spend less time searching for work and more time doing what you love.`,
  step1: `Pass our tests and interviews.`,
  step2: `We match you with partner companies.`,
  step3: `You become a part of their team.`,
  feature1: {
    header: 'Run by Freelancers',
    text: `We’ve worked as freelancers ourselves. We filter out the bad clients and projects for you.`,
  },
  feature2: {
    header: 'Match with Interesting Opportunities',
    text: `Part of freelancing is always facing new challenges. We do our best to find you interesting and challenging projects to work on.`,
  },
  feature3: {
    header: 'Competitive Pay',
    text: `Skilled projects are well paid. We match you with high-quality projects at both large enterprises and up-and-coming startups.`,
  },
}
